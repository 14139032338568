@import url("https://fonts.googleapis.com/css2?family=Inconsolata");

body {
    margin: 0;
    font-family: 'Inconsolata', serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


html {
    --section-background-color: linear-gradient(to bottom left,
            rgba(17, 16, 16, 0.582),
            rgba(12, 8, 24, 0.904));

    --image-gradient: linear-gradient(to bottom left,
            rgba(17, 16, 16, 0.678),
            rgba(12, 10, 22, 0.863));

    --imp-text-color: #58b6ef;
}

.blue {
    color: var(--imp-text-color) !important;
}

button:focus {
    box-shadow: none !important;
}

/* --------- */
/*  Preloader */
/* --------- */
#preloader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999999;
    background-color: #ffffff;
    background-image: url(./visuals/loader.svg);
    background-repeat: no-repeat;
    background-position: center;
}


/* --------- */
/*Scrollbar   */
/* --------- */

::-webkit-scrollbar {
    width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #000000;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #6d6d6d;
    border-radius: 12px;
}

/* --------- */
/* Navbar Section  */
/* --------- */

.navbar {
    position: fixed !important;
    transition: all 0.3s ease-out 0s !important;
    padding: 0.3rem 2rem !important;
    font-size: 1.4rem !important;
}


.navbar-nav .nav-link {
    color: white !important;
    padding-right: 1rem !important;
    padding-left: 1rem !important;
}

.nav-link {
    padding: 0.8rem 1rem !important;
}

.navbar-nav .nav-item {
    position: relative;
    margin-left: 20px;
}

.navbar-nav .nav-item a {
    font-weight: 400;
    transition: all 0.3s ease-out 0s;
    position: relative;
}

.navbar-nav .nav-item a::after {
    content: "";
    position: relative;
    display: block;
    height: 5px;
    width: 0;
    border-radius: 16px;
    background: #58b6ef;
    bottom: 1px;
    left: 0;
    transition: all 0.3s ease-out 0s;
}

.navbar-nav .nav-item a:hover::after {
    width: 100%;
}

/* --------- */
/* Home section */
/* --------- */
#tsparticles {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 0;
    margin: 0;
    z-index: -1;
}

.home-header {
    padding-top: 80px !important;
    text-align: left;
    z-index: 1;
}

.home-content {
    padding: 9rem 0 2rem !important;
    color: whitesmoke;
    text-align: left;
    z-index: 1;
}

.heading {
    font-size: 2.4em !important;
    padding-left: 50px !important;
}

.heading-name {
    font-size: 2.5em !important;
    padding-left: 45px !important;
}

.main-name {
    color: #58b6ef;
}

.Typewriter__wrapper {
    font-size: 3em !important;
    color: #58b6ef !important;
    font-weight: 600 !important;
}

.Typewriter__cursor {
    font-size: 3em !important;
    color: #58b6ef !important;
}

@media (max-width: 767px) {
    .Typewriter__wrapper {
        font-size: 1.4em !important;
        font-weight: 500 !important;
        position: relative !important;
    }

    .Typewriter__cursor {
        display: none !important;
    }
}

.myAvatar {
    justify-content: center !important;
    padding-top: 1em !important;
}

@media (max-width: 767px) {
    .myAvtar {
        padding-top: 2em !important;
        padding-bottom: 2em !important;
    }
}

/* --------- */
/* About  section */
/* --------- */
.home-about-section {
    position: relative;
    padding-bottom: 70px !important;
    padding-top: 70px !important;
    background-color: #000000;
}

.home-about-description {
    color: white !important;
    padding-top: 100px !important;
    padding-bottom: 20px !important;
    text-align: right;
}

.home-about-body {
    padding-top: 50px;
    font-size: 1.3em !important;
    text-align: right;
}

/* --------- */
/* About Socials section */
/* --------- */
.home-about-social {
    text-align: center !important;
    padding-top: 25px;
    color: white !important;
}

.home-about-social-links {
    justify-content: center !important;
    padding-top: 15px !important;
    display: inline-block !important;
    position: relative !important;
    padding-inline-start: 0 !important;
}

.home-social-icons {
    position: relative !important;
    display: inline-block !important;
    width: 40px !important;
    height: 40px !important;
    text-align: center !important;
    font-size: 1.2em !important;
    line-height: 2em !important;
    background: rgba(255, 255, 255, 0.972) !important;
    border-radius: 50% !important;
    transition: 0.5s !important;
}

.home-social-icons::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: #58b6ef;
    transition: 0.5s;
    transform: scale(0.9);
    z-index: -1;
}

.home-social-icons:hover::before {
    transform: scale(1.1);
    box-shadow: 0 0 15px #58b6ef;
}

.home-social-icons:hover {
    color: #58b6ef;
    box-shadow: 0 0 5px #58b6ef;
    text-shadow: 0 0 2px #58b6ef;
}

.social-icons {
    display: inline-block !important;
    padding-right: 15px;
    padding-left: 15px;
}

.icon-colour {
    color: #58b6ef !important;
}

/* --------- */
/* Mint section */
/* --------- */
.home-mint-section {
    position: relative;
    padding-bottom: 70px !important;
    padding-top: 70px !important;
    background-color: #000000;
}

.home-mint-description {
    color: white !important;
    padding-top: 100px !important;
    padding-bottom: 20px !important;
    text-align: center;
}

.home-mint-body {
    padding-top: 50px;
    font-size: 1.3em !important;
    text-align: center;
}

/* --------- */
/* Footer */
/* --------- */
.footer {
    bottom: 0 !important;
    padding-top: 10px !important;
    padding-bottom: 8px !important;
    background-color: #000000;
}

.footer-copywright {
    text-align: center !important;
}

.footer-body {
    text-align: center !important;
}

@media (max-width: 767px) {
    .footer-copywright {
        text-align: center !important;
    }

    .footer-body {
        text-align: center !important;
    }
}

.footer h3 {
    font-size: 1em;
    color: white !important;
    margin-top: 0.5em !important;
    margin-bottom: 0.5em !important;
}

.footer-icons {
    margin-top: 0.5em !important;
    margin-bottom: 0.5em !important;
    padding: 0 !important;
}
